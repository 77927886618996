import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = ({ keywords, title, description, url, imageAlt }) => {
  return (
    <Helmet>
      
      <title>{title ? `ELPRO-SHOP ${title}` : "ELPRO-SHOP"}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta
        name="keywords"
        content={
          keywords
            ? keywords
            : "מכונת תספורת, מכונת גילוח, שיער, מכונות שיער, הסרת שיער, גוף חלק,גילוח שיער, גילוח ביצים, מכונת גילוח לגבר, גילוח "
        }
      />
      <meta property="og:type" content="Barbershop" />
      <meta
        property="og:image"
        content={"https://www.elpro-shop.co.il/logo_img.png"}
      />
      <meta
        property="og:image:alt"
        content={imageAlt ? imageAlt : "לוגו elpro"}
      />
      <meta name="owner" content={"elpro-shop"} />
      <meta name="language" content={"HE"} />
      <meta name="author" content={"elia avitan"} />
      <meta name="email" content={"eliavitan12345@gmail.com"} />
      <meta name="email" content={"26life72@gmail.com"} />
      <meta property="og:email" content={"26life72@gmail.com"} />
      <meta property="og:email" content={"eliavitan12345@gmail.com"} />
      <meta name="phone_number" content={"+972 50-802-3801"} />
      <meta property="og:phone_number" content={"+972 50-802-3801"} />
      <meta name="url" content={url ? url : "https://www.elpro-shop.co.il"} />
      <meta property="og:url" content={url ? url : "https://www.elpro-shop.co.il"} />
      <meta
        name="description"
        content={
          description
            ? description
            : "פתרונות גילוח, תספורת, איבזרי ספרים, מספרה, גילוח הגוף, מכונות גילוח, מכונות תספורת, גילוח שיער, גילוח ביצים, גוף חלק"
        }
      />
      <meta property="og:title" content={"elpro"} />
      <meta
        property="og:description"
        content={
          description
            ? description
            : "פתרונות גילוח, תספורת, איבזרי ספרים, מספרה, גילוח הגוף, מכונות גילוח, מכונות תספורת, גילוח שיער, גילוח ביצים, גוף חלק"
        }
      />
    </Helmet>
  );
};

export default SEO;
