import React from "react";
import styles from "./social.module.css";
import { Link } from "react-router-dom";
import { LiaFacebookMessenger } from "react-icons/lia";
import { SiWaze } from "react-icons/si";
import { MdWhatsapp } from "react-icons/md";
import { PiPhoneCallFill } from "react-icons/pi";
import { AiOutlineMessage } from "react-icons/ai";

const SocialNetwork = ({
  WhatsApp,
  Facebook,
  Instagram,
  Phone,
  Message,
  weze,
}) => {

  const gradient = "url(#blue-gradient)" ;
  
  return (
    <div className={styles.socialContainer}>
      {WhatsApp ? (
        <div className={styles.itemIcon}>
           
          <Link aria-label="Read more about Seminole tax hike" to={WhatsApp}>
          <MdWhatsapp size={30}/>
          </Link>
        </div>
      ) : (
        ""
      )}
      {Facebook ? (
        <div className={styles.itemIcon}>
          <Link aria-label="Read more about Seminole tax hike" to={Facebook}>
          <LiaFacebookMessenger size={32}/>
          </Link>
        </div>
      ) : (
        ""
      )}
      {Message ? (
        <div className={styles.itemIcon}>
          <Link aria-label="Read more about Seminole tax hike" to={Message}>
          <AiOutlineMessage  size={28} />
          </Link>
        </div>
      ) : (
        ""
      )}
      {weze ? (
        <div className={styles.itemIcon}>
         
          <Link aria-label="Read more about Seminole tax hike" to={weze}>
          <SiWaze  size={28}  />
          </Link>
        </div>
      ) : (
        ""
      )}
      {Phone ? (
        <div className={styles.itemIcon}>
          <Link aria-label="Read more about Seminole tax hike" to={Phone}>
          <PiPhoneCallFill size={28} />
          </Link>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SocialNetwork;
