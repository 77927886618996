import header_main_img from "../../assets/images/header_main_img.webp";
import about_main_img from "../../assets/images/about_main_img.webp";
import form_main_img from "../../assets/images/form_main_img.jpg";
import professionals_main_img from "../../assets/images/professionals_main_img.jpg";
import video_cover_img from "../../assets/images/video_cover_img.webp";
// import customer_img_1 from "../../assets/images/customer_img_1.jpg";
// import customer_img_2 from "../../assets/images/customer_img_2.jpg";
// import customer_img_3 from "../../assets/images/customer_img_3.jpg";
// import customer_img_4 from "../../assets/images/customer_img_4.jpg";
// import customer_img_5 from "../../assets/images/customer_img_5.jpg";
// import customer_img_6 from "../../assets/images/customer_img_6.jpg";

import portfolio_img_1 from "../../assets/images/portfolio_img_1.jpg";
import portfolio_img_2 from "../../assets/images/portfolio_img_2.jpg";
import portfolio_img_3 from "../../assets/images/portfolio_img_3.jpg";

// import navbar_logo_img from "../../assets/images/navbar_logo_img.png";
import navbar_logo_white_dev_img from "../../assets/images/navbar_logo_white_dev_img.png";

import service_1 from "../../assets/images/service_1.png";
import service_2 from "../../assets/images/service_2.png";
import service_3 from "../../assets/images/service_3.png";

import TEKEN from "../../assets/images/TEKEN.png";

const images = {
  header_main_img,
  about_main_img,
  form_main_img,
  professionals_main_img,
  video_cover_img,
  portfolio_img_1,
  portfolio_img_2,
  portfolio_img_3,
  // navbar_logo_img,
  navbar_logo_white_dev_img,
  service_1,
  service_2,
  service_3,
  TEKEN,
};

export default images;
