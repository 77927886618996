import React, { useState } from "react";
import "./navbarStyle.css";
import { mainRoutes } from "../../pages";
import { Link } from "react-router-dom";
import images from "../../constants/images/images";
import { LiaFacebookMessenger } from "react-icons/lia";
import { SiWaze } from "react-icons/si";
import { MdWhatsapp } from "react-icons/md";
import { PiPhoneCallFill } from "react-icons/pi";
import { IoMdRocket } from "react-icons/io";

const Navbar = () => {
  const [navToggle, setNavToggle] = useState(false);
  const navHandler = () => {
    setNavToggle((prevData) => !prevData);
  };

  return (
    <nav>
      <div className="containerNavbar">
        <div className="logo">
          <img
            style={{ width: "90px", height: "100%" }}
            src={images.navbar_logo_white_dev_img}
            alt=""
          />
          <div
            type="button"
            className={`hamburger-menu ${
              navToggle ? "hamburger-menu-change" : ""
            }`}
            onClick={navHandler}
          >
            <div className="bar-top"></div>
            <div className="bar-middle"></div>
            <div className="bar-bottom"></div>
          </div>
        </div>

        <div
          className={`navbar-collapse ${
            navToggle ? "show-navbar-collapse" : ""
          }`}
        >
          <div className="navbar-collapse-content">
            <div className="links">
              {mainRoutes.map((item, i) => {
                if (item.isNavbar) {
                  return (
                    <Link key={i} to={item.path} aria-label="Read more about Seminole tax hike">
                      {item.linkLabel}
                    </Link>
                  );
                }
              })}
            </div>
            <ul className="navbar-social">
              <li className="text-white">
                <Link
                  to={"https://wa.me/9720508142140"}
                  className="flex flex-center"
                  aria-label="Read more about Seminole tax hike"
                >
                  <MdWhatsapp size={30} />
                </Link>
              </li>
              <li className="text-white">
                <Link
                  to={"https://www.facebook.com/"}
                  className="flex flex-center"
                  aria-label="Read more about Seminole tax hike"
                >
                  <LiaFacebookMessenger size={32} />
                </Link>
              </li>
              {/* <li className="text-white">
                <Link to="" className="flex flex-center">
                  <SiWaze size={28} />
                </Link>
              </li> */}
              <li className="text-white">
                <Link to={"tel:+9720508142140"} className="flex flex-center" aria-label="Read more about Seminole tax hike">
                  <PiPhoneCallFill size={28} />
                </Link>
              </li>
            </ul>
          </div>
          {/* ) : (
          <div className="links">
            {mainRoutes.map((item, i) => (
              <Link key={i} to={item.path}>
                {item.linkLabel}
              </Link>
            ))}
          </div>
        )} */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
