import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import { mainRoutes } from "./pages/index";
import Header from "./components/header/Header";
import SEO from "./SEO/SEO";

function App() {
  return (
    <BrowserRouter>
      <SEO />
      <Navbar />
      <Header />
      <Suspense
        fallback={
          <div className="container">
            <h1>loading.....</h1>
          </div>
        }
      >
        <main>
          <Routes>
            {mainRoutes.map((item, i) => (
              <Route key={i} path={item.path} Component={item.component} />
            ))}
          </Routes>
        </main>
      </Suspense>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
